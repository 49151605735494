import Exim from '@/models/Exim.js'
import LandingPageHTMLSection from '../LandingPages/LandingPageSections/LandingPageHTMLSection.js'
import LandingPagePrimaryQuoteSection from '../LandingPages/LandingPageSections/LandingPagePrimaryQuoteSection.js'
import LandingPageImageSection from '../LandingPages/LandingPageSections/LandingPageImageSection.js'
import LandingPageSliderSection from '../LandingPages/LandingPageSections/LandingPageSliderSection.js'
import LandingPageStorefrontSection from '../LandingPages/LandingPageSections/LandingPageStorefrontSection.js'
import LandingPageChatSection from '../LandingPages/LandingPageSections/LandingPageChatSection.js'
import LandingPageBuiltInSection from '../LandingPages/LandingPageSections/LandingPageBuiltInSection.js'
import Location from '@/features/Proxy/Location.js'
import POC from '../POC/POC.js'



class Affiliate extends Exim {


	id = 0
	urlSlug = ''
	couponCode = null
	_logoFilename = null
	name = ''

	tags = []
	sections = []
	pocs = []

	landingPageID = 0
	salesRepresentativeID = 0
	landingPageTemplateLabel = null
	dateCreated = null

	orderCount = null
	recentOrderTimestamp = null
	contactCount = null
	shortcutURLs = null          // array<Proxy/Location>



	get logoFilename() { return this._logoFilename }
	set logoFilename( value ) { this._logoFilename = value ? value : null }


	get landingPageIDString() { return '' + (this.landingPageID || 0) }
	set landingPageIDString(value) { this.landingPageID = parseInt(value) }


	get salesRepIDString() { return ''+ (this.salesRepresentativeID || 0) }
	set salesRepIDString(value) { this.salesRepresentativeID = parseInt(value) }




	addTag( tag ) {
		if( tag === '' || tag === null || tag === undefined ) return
		if( this.tags.indexOf(tag) === -1 ) this.tags.push( tag )
	}

	removeTag( tag ) {
		var idx = this.tags.indexOf( tag )
		if( idx >= 0 ) this.tags.splice( idx, 1 )
	}




	clone() { return Affiliate.import( this.export() ) }
	isSame(obj) { return JSON.stringify( this.export() ) == JSON.stringify( obj.export() ) }


	static import(obj) {
		const item = super.import( obj, [ 'sections', 'shortcutURLs' ] )

		if( obj.dateCreated != null ) item.dateCreated = new Date( obj.dateCreated * 1000 )

		// Sections
		if(obj.sections) {
			for(let section of obj.sections) {
				let importedSection = null
				if(!section.type || section.type == 'html') importedSection = LandingPageHTMLSection.import(section)
				else if(section.type == 'primaryQuote') importedSection = LandingPagePrimaryQuoteSection.import(section)
				else if(section.type == 'image') importedSection = LandingPageImageSection.import(section)
				else if(section.type == 'slider') importedSection = LandingPageSliderSection.import(section)
				else if(section.type == 'storefront') importedSection = LandingPageStorefrontSection.import(section)
				else if(section.type == 'chat') importedSection = LandingPageChatSection.import(section)
				else if(section.type == 'builtin') importedSection = LandingPageBuiltInSection.import(section)
				item.sections.push( importedSection )
			}
		}
		
		// Points of Contact
		if( obj.pointsOfContact ) {
			for( let poc of obj.pointsOfContact ) {
				item.pocs.push( POC.import( poc ) )
			}
		}

		// Routes / Shortcut URLs
		if( obj.shortcutURLs ) {
			item.shortcutURLs = []
			for( let location of obj.shortcutURLs ) {
				item.shortcutURLs.push( Location.import( location ) )
			}
		}

		return item
	}


	export() {

		const data = super.export( [ 'tags', 'sections', 'pocs' ] )
		data.dateCreated = this.dateCreated ? Math.round( this.dateCreated.getTime() / 1000 ) : null

		// Tags
		data.tags = []
		for(let tag of this.tags) data.tags.push(tag)

		// Sections
		data.sections = []
		for(let sec of this.sections) data.sections.push(sec.export())

		// POCs
		data.pointsOfContact = []
		for(let poc of this.pocs) data.pointsOfContact.push(poc.export())

		return data
	}

}

export default Affiliate
