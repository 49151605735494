<template>
<section>

	<div class="StickySaveButton">
		<button class="pillButton" @click="save()" :class="{ 'disabled' : !changesMade }" :disabled="!changesMade">
			Save Changes
		</button>
	</div>

	<section class='Tab sticky-top'>
		<button @click="mode='general'" :class="{'active':mode==='general'}">General</button>
		<button @click="mode='landingpage'" :class="{'active':mode==='landingpage'}">Landing Page<span v-if="selectedLandingPage"> ({{ selectedLandingPage.adminLabel }})</span></button>
		<button @click="mode='orders'" class="ml-1" :class="{'active':mode==='orders'}">Orders</button>
		<button @click="mode='licenses'" :class="{'active':mode==='licenses'}">Licenses</button>
		<button @click="mode='usertime'" :class="{'active':mode==='usertime'}">Study Time</button>
		<button @click="mode='reviews'" class="ml-1" :class="{'active':mode==='reviews'}">Reviews</button>
		<button @click="mode='modalmessages'" :class="{'active':mode==='modalmessages'}">Modal Messages</button>
	</section>

	<section class="px-1 pb-1">

		<!-- General Tab -->
		<section v-if="mode === 'general'">
			<div class="flex-row flex-align-stretch flex-gap-1 mt-1">
				<fieldset class="flex-column flex-gap-1">
					<legend>General</legend>

					<label>
						<div>Affiliate Name:</div>
						<input type="text" v-model="newItem.name" style="min-width: 40vw" placeholder="Affiliate Name" />
					</label>

					<label :class="{ 'warning' : !newItem.urlSlug }">
						<div>URL slug: <span v-if="newItem.urlSlug" class="bold font-size-1-5 ml-05">{{ newItem.urlSlug }}</span></div>
						<div v-if="!newItem.urlSlug" class="flex-row flex-align-center flex-gap-2">
							<input type="text" v-model="newSlug" :disabled="!isNew && !!newItem.urlSlug" :class="{ 'bg-red color-white bold' : slugAvailable === false }" style="min-width: 20vw" /> <!-- !!newItem.urlSlug (double-not): required to prevent it from evaluating idiot-styles -->
							<div v-if="!isNew && newSlug != null && slugAvailable === true" class="flex-row flex-align-center flex-gap-05">
								<button class="pillButton secondary" @click="newSlug = null">cancel</button>
								<button class="pillButton" @click="saveNewSlug()">save</button>
							</div>
						</div>
						<div v-if="slugAvailable === false" class="bold color-red mt-05">&ldquo;{{ newSlug }}&rdquo; is not available</div>
					</label>

					<label>
						<div>Tags:</div>
						<GenericTagAssignDropdown :taggedObject="newItem" tagType="affiliate" @addTag="tag => newItem.addTag( tag )" @removeTag="tag => newItem.removeTag( tag )" />
					</label>
					
					<section v-if="newItem.id">
						<div class="mt-1">Images / Files:</div>
						<AttachmentFileViewer hostType="affiliate" :hostID="newItem.id" ref="fileViewer" />
						<AttachmentFileUploader hostType="affiliate" :hostID="newItem.id" @upload="$refs.fileViewer.initialize()" />
					</section>

					<!-- <div class="flex-row flex-align-center flex-gap-1 my-1">
						<label class="flex-grow">
							<div>Logo Image File Name:</div>
							<input type="text" v-model="newItem.logoFilename" class="w-100" placeholder="Logo Image File Name" />
						</label>
						<div v-if="newItem.logoFilename" class="mt-1"><img :src="logoURL" /></div>
					</div> -->

					<div class="hr-light" />
					
					<div>
						<div>V9 Coupon Code: <strong>{{ newItem.couponCode }}</strong></div>
						<div v-if="newItem.urlSlug">Landing Page: <strong><a target="new" :href="`${baseURL}/affiliate/${newItem.urlSlug}`">{{ baseURL }}/affiliate/{{ newItem.urlSlug }}</a></strong></div>
						<div v-if="newItem.urlSlug">Comments Page: <strong><a target="new" :href="`${baseURL}/comments/${newItem.urlSlug}`">{{ baseURL }}/comments/{{ newItem.urlSlug }}</a></strong></div>
						
						<div v-if="newItem.urlSlug" class="mt-2 pl-05" style="border-left: 5px solid var(--ekno-blue);">
							
							Shortcut URLs:
							<div v-for="(route, r_idx) in proxyRoutes" :key="route.id" class="ml-2 pa-05 my-05 border round-05 flex-row flex-align-center flex-justify-between">
								<div>
									<div v-for="(s, s_idx) in route.sources" :key="s.vueID" class="pa-05" :class="{ 'bg-green-90' : r_idx == 0 && s_idx == 0 }">
										<a :href="s.getFQDN( true )" target="new">{{ s.getFQDN( true ) }}</a>
										<span v-if="r_idx == 0 && s_idx == 0" class="bold"> (primary) <span class="icon-info" @mouseenter="t.s($event, 'top', 0, 10000)" @mouseleave="t.h($event)" tooltip='This shortcut URL will be used in the "AffiliateLink" shortcode, in emails sent from the Message Center' /></span>
									</div>
								</div>
								<div><ToggleButton v-model="route.enabled" :sync="true" @change="toggleRouteEnabled( route )" /></div>
							</div>

							<div v-if="showAddRoute" class="flex-row flex-align-center flex-justify-between flex-gap-1">
								<div class="flex-row flex-align-center flex-gap-1">
									<div>eknowledge.com/<input type="text" class="flex-grow" :class="{ 'warning' : routePathAvailable === false }" v-model="addRoutePath" placeholder="URL slug" /></div>
									<div v-if="addRoutePath && routePathAvailable"><button class="pillButton" @click="addCustomRoute()">Save</button></div>
									<div v-else-if="routePathAvailable === false">This URL is already in use</div>
									<div v-else-if="addRoutePath && routePathAvailable === null"><span class="icon-loop2 spin1" /></div>
								</div>
								<div><button class="pillButton secondary" @click="showAddRoute = false">cancel</button></div>
							</div>
							<div v-else class="ma-05 flex-row flex-justify-end flex-gap-1">
								<button v-if="!hasDefaultRoute" class="pillButton secondary bg" @click="addDefaultRoute()"><span class="icon-plus" /> Add "{{ newItem.urlSlug }}" shortcut URL</button>
								<button class="pillButton secondary bg" @click="showAddRoute = true"><span class="icon-plus" /> Add Custom ...</button>
							</div>
						</div>
						<div v-else class="mt-1 warning">To add short URLs, please provide a URL Slug and choose a Landing Page template</div>

					</div>
					
				</fieldset>

				<fieldset class="flex-grow" style="padding: 0;">
					<legend>Contacts</legend>
					<PocList v-if="newItem.id" :affiliateID="newItem.id" style="max-height: 450px; overflow: auto; padding: 1em;" />
				</fieldset>
			</div>

			<fieldset class="mt-2" style="width:100%;">
				<legend>Commissions:</legend>
				<CommissionWidget v-if="newItem.id" :assignments="newItem.commissions" :hostType="'affiliate'" :hostID="newItem.id" />
			</fieldset>

		</section> <!-- General Tab -->


		<!-- Landing Page Tab -->
		<section v-if="mode === 'landingpage'">

			<div class="flex-row flex-align-start flex-justify-between flex-gap-3 my-1">
				<label class='flex-row flex-align-center flex-gap-05'>
					<div>URL slug:</div>
					<input v-if="isNew || !newItem.urlSlug" v-model="newItem.urlSlug" />
					<div v-else><strong>{{ newItem.urlSlug }}</strong></div>
				</label>

				<label class="center">
					<div>Landing Page Template:</div>
					<select v-if="loaded" v-model="newItem.landingPageIDString" @change="selectLandingPage()">
						<option v-for="[id, lp] in landingPages" :value="id" :key="id">{{lp.adminLabel}}</option>
					</select>
				</label>

				<div><strong><a target="new" :href="`${baseURL}/editaffiliate/${newItem.urlSlug}`">Preview</a></strong></div>
			</div>


			<div v-if="selectedLandingPage" class="form-entry-block">
				<div v-for="(section, index) in newItem.sections" :key="section.name" class="section">

					<!-- Display all blocks w/ defaults from Landing Page -->
					<LandingPageHTMLSection v-if="section.type == 'html'" :section="section" :isTemplate="false" :isDefault="!sections.isOverride(section)" @update="updateSection(section)" @revert="revertSection(section, index)" />
					<LandingPagePrimaryQuoteSection v-else-if="section.type == 'primaryQuote'" :section="section" :isTemplate="false" :isDefault="!sections.isOverride(section)" @update="updateSection(section)" @revert="revertSection(section, index)" />
					<LandingPageImageSection v-else-if="section.type == 'image'" :section="section" :isTemplate="false" :isDefault="!sections.isOverride(section)" @update="updateSection(section)" @revert="revertSection(section, index)" />
					<LandingPageSliderSection v-else-if="section.type == 'slider'" :section="section" :isTemplate="false" :landingPage="selectedLandingPage" @update="updateSection(section)" @revert="revertSection(section, index)" />
					<LandingPageStorefrontSection v-else-if="section.type == 'storefront'" :section="section" :templateSection="selectedLandingPage.sections[index]" :isTemplate="false" :isDefault="!sections.isOverride(section)" @update="updateSection(section)" @revert="revertSection(section, index)" />
					<LandingPageChatSection v-else-if="section.type == 'chat'" :section="section" :isTemplate="false" :isDefault="!sections.isOverride(section)" @update="updateSection(section)" @revert="revertSection(section, index)" />
					<LandingPageBuiltInSection v-else-if="section.type == 'builtin'" :affiliateID="newItem.id" :section="section" :isTemplate="false" :isDefault="!sections.isOverride(section)" @update="updateSection(section)" @revert="revertSection(section, index)" />
				</div>
			</div>

		</section>


		<section v-if="mode === 'orders'">
			<p class="center"><b>All-time order statistics ({{newItem.name}})</b></p>
			<OrdersDashboard :affiliateID="newItem.id" class="mb-4" />
			<p class="center"><b>Order History ({{newItem.name}})</b></p>
			<OrderSearchPane :affiliateID="newItem.id" :showDashboard="false" />
		</section>
		<LicensesList v-if="mode === 'licenses'" :affiliate="newItem" />
		<UserTimeList v-if="mode === 'usertime'" :affiliateID="newItem.id" />
		<ReviewsDashboard v-if="mode === 'reviews'" :affiliateID="newItem.id" :affiliateIn="newItem" />
		<ModalMessageList v-if="mode === 'modalmessages'" :affiliateID="newItem.id" />
	
	</section>

</section>
</template>

<script>
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import ProxyAPI from '@/api/ProxyAPI.js'
import Affiliate from './Affiliate.js'
import LandingPage from '../LandingPages/LandingPage.js'
import SectionManager from './SectionManager.js'
import Route from '@/features/Proxy/Route.js'
import { base_url_image } from '@/Config.js'
import { powerprep_live_domain, powerprep_base_url } from '@/Config.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

import GenericTagAssignDropdown from '@/components/utilities/GenericTagAssignDropdown.vue'
import AttachmentFileUploader from '@/features/FileUploader/AttachmentFileUploader.vue'
import AttachmentFileViewer from '@/features/FileUploader/AttachmentFileViewer.vue'
import LandingPageHTMLSection from '../LandingPages/LandingPageSections/LandingPageHTMLSection.vue'
import LandingPagePrimaryQuoteSection from '../LandingPages/LandingPageSections/LandingPagePrimaryQuoteSection.vue'
import LandingPageImageSection from '../LandingPages/LandingPageSections/LandingPageImageSection.vue'
import LandingPageSliderSection from '../LandingPages/LandingPageSections/LandingPageSliderSection.vue'
import LandingPageStorefrontSection from '../LandingPages/LandingPageSections/LandingPageStorefrontSection.vue'
import LandingPageChatSection from '../LandingPages/LandingPageSections/LandingPageChatSection.vue'
import LandingPageBuiltInSection from '../LandingPages/LandingPageSections/LandingPageBuiltInSection.vue'
import CommissionWidget from '../CommissionWidget.vue'
import LicensesList from '@/features/Licenses/LicensesList.vue'
import UserTimeList from '@/features/LoginTracking/UserTimeList.vue'
import ModalMessageList from '@/features/ModalMessages/ModalMessageList.vue'
import ReviewsDashboard from '@/features/Reviews/ReviewsDashboard.vue'
import PocList from '../POC/PocList.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: "AffiliateDetails",

	components: {
		GenericTagAssignDropdown,
		AttachmentFileUploader,
		AttachmentFileViewer,
		LandingPageHTMLSection,
		LandingPagePrimaryQuoteSection,
		LandingPageImageSection,
		LandingPageSliderSection,
		LandingPageStorefrontSection,
		LandingPageChatSection,
		LandingPageBuiltInSection,
		CommissionWidget,
		PocList,
		LicensesList,
		UserTimeList,
		ModalMessageList,
		ReviewsDashboard,
		OrdersDashboard: () => import('@/features/orders/OrdersDashboard.vue'),
		OrderSearchPane: () => import('@/features/orders/OrderSearchPane.vue'),
		ToggleButton,
	},

	props: {
		itemIn: Affiliate,
		isNew: Boolean,
		view: {
			type: String,
			default: ''
		},
	},

	data() {
		return {
			mode: 'general',
			newItem: Affiliate,
			originalItem: Affiliate,
			loaded: false,

			newSlug: null,
			slugAvailable: null,
			slugTimeoutID: null,

			tagToAdd: null,  // text

			landingPages: new Map(),
			selectedLandingPage: null,
			sections: new SectionManager(),

			proxyRoutes: [],
			hasDefaultRoute: false,
			showAddRoute: false,
			addRoutePath: '',
			routeTimeoutID: null,
			routePathAvailable: null,
		}
	},

	created() {
		this.initialize();
	},

	computed: {
		t() { return Tooltips },

		changesMade() {
			return this.newItem && this.originalItem && !this.originalItem.isSame(this.newItem)
		},

		logoURL() { return `${base_url_image}/${this.newItem.logoFilename}` },
		baseURL() { return powerprep_base_url },
	},


	watch: {
		itemIn() { this.initialize() },
		mode() { this.loadProxyRoutes() },
		
		async addRoutePath( value ) {
			this.routePathAvailable = null
			if( this.routeTimeoutID ) clearTimeout( this.routeTimeoutID )
			this.routeTimeoutID = setTimeout( async () => { this.routePathAvailable = await this.isRouteAvailable( value ) }, 500 )
		},

		selectedLandingPage() {
			if(!this.loaded) return
			this.initSections()
		},

		async newSlug( slug ) {
			this.slugAvailable = null
			if( slug === '' || slug === null ) return

			clearTimeout( this.slugTimeoutID )
			this.slugTimeoutID = setTimeout( async () => {
				const data = await SalesManagementAPI.isSlugAvailable( slug )
				this.slugAvailable = data.value
			}, 500)
		}
	},


	methods: {

		async initialize() {
			this.originalItem = this.itemIn.clone()
			this.newItem = this.itemIn.clone()
			if(this.view) this.mode = this.view

			this.landingPages.clear()
			const res = await SalesManagementAPI.getLandingPages()
			for(let item of res) {
				const lp = LandingPage.import( item )
				this.landingPages.set( lp.id, lp )
			}

			this.selectLandingPage()
			this.loaded = true

			this.loadProxyRoutes()
		},


		async saveNewSlug() {
			await SalesManagementAPI.setSlug( this.newItem.id, this.newSlug )
			this.newItem.urlSlug = this.newSlug
			this.newSlug = null
		},


		selectLandingPage() {
			if( this.newItem && this.newItem.landingPageID ) this.selectedLandingPage = this.landingPages.get( this.newItem.landingPageID )
			else this.selectedLandingPage = null
		},


		initSections() {
			this.sections.reset()
			let newSections = []

			this.originalItem.sections = []
			for(let lpSection of this.selectedLandingPage.sections) {
				let affOverride = false

				let affSection = this.findAffiliateSection(lpSection)
				if(affSection) {
					affOverride = true
					if(affSection.type == 'slider') this.initSlides(affSection)
				} else {
					affSection = lpSection.clone()
				}


				newSections.push(affSection)
				this.originalItem.sections.push(affSection.clone())
				this.sections.setSection(affSection, affOverride)
			}

			this.newItem.sections = newSections
		},


		initSlides(section) {
			const lpSection = this.findLandingPageSection(section);
			if(!lpSection) return;

			const slides = [];
			for(let lpSlide of lpSection.slides) {
				lpSlide = lpSlide.clone();
				lpSlide.isDefault = true;

				let affSlide = section.getSlide(lpSlide.id);
				if(affSlide && !affSlide.isSame(lpSlide)) {
					affSlide = affSlide.clone();
					affSlide.isDefault = false;

					slides.push(affSlide);
				} else slides.push(lpSlide);
			}
			section.slides = slides;
		},


		findAffiliateSection(section) {
			return this.newItem.sections.find(elem => elem.name === section.name) || null;
		},

		findLandingPageSection(section) {
			return this.selectedLandingPage.sections.find(elem => elem.name == section.name) || null;
		},

		updateSection(section) {
			console.debug("update section")
			const lpSection = this.findLandingPageSection(section);
			const affSection = this.findAffiliateSection(section);

			this.sections.setSection(section, !affSection.isSame(lpSection) || !lpSection.isSame(section));

			const index = this.newItem.sections.findIndex(elem => elem.name == section.name);
			this.$set(this.newItem.sections, index, section);

		},

		revertSection(section, index) {
			console.debug("revert section")
			const lpSection = this.findLandingPageSection(section);
			const newSection = lpSection.clone();
			this.$set(this.newItem.sections, index, newSection)
			this.sections.setSection(newSection, false);
		},


		async save() {
			const itemToSave = this.newItem.clone();
			console.debug( this.newItem.sections, itemToSave.sections )
			const sections = [];
			for(let section of itemToSave.sections) {
				if(this.sections.isOverride(section)) sections.push(section);

				// Only include slides that have been changed or added!
				if(section.type == 'slider') {
					const lpSection = this.findLandingPageSection(section);
					const slides = section.slides;
					section.slides = [];

					for(let slide of slides) {
						let lpSlide = lpSection.getSlide(slide.id);
						if(!lpSlide || (!slide.isSame(lpSlide) && !slide.isDefault)) section.slides.push(slide);
					}

				}
			}

			itemToSave.sections = sections;


			if( this.isNew ) {
				if( this.newSlug ) itemToSave.urlSlug = this.newSlug
				await SalesManagementAPI.createAffiliate( itemToSave.export() )
				const obj = await SalesManagementAPI.getAffiliate( itemToSave.urlSlug )
				const newItem = Affiliate.import( obj )
				this.$emit( 'itemAdded', newItem )
			} else {
				await SalesManagementAPI.editAffiliate( itemToSave.export() )
				this.$emit( 'itemEdited', this.newItem.clone() )
			}
		},



		addTag( tag = null ) {
			if( tag ) this.tagToAdd = tag
			
			if( !this.tagToAdd ) return
			
			this.newItem.tags.push( this.tagToAdd )
			this.tagToAdd = null
		},


		removeTag( tag ) {
			const idx = this.newItem.tags.indexOf(tag);
			if( idx < 0 ) return

			this.newItem.tags.splice(idx, 1);
		},










		// ----------------------------------------------------------------
		// Proxy Routes
		// ----------------------------------------------------------------

		async loadProxyRoutes() {
			if( !this.newItem.urlSlug ) return
			
			this.proxyRoutes = []
			const proxyRes = await ProxyAPI.searchRoutes( { destinationPath: `affiliate/${ this.newItem.urlSlug }`, fuzzyBefore: false, fuzzyAfter: false } )
			
			for( var r of proxyRes.data ) {
				const route = Route.import( r )
				this.proxyRoutes.push( route )

				// Search for the default route
				for( var src of route.sources ) {
					if( src.path === this.newItem.urlSlug ) this.hasDefaultRoute = true
				}
			}
		},



		async addDefaultRoute() {
			const sourcePath = this.newItem.urlSlug
			const avail = await this.isRouteAvailable( sourcePath )
			if( !avail ) return

			const isNew = this.proxyRoutes.length === 0
			const route = this.proxyRoutes.length ? this.proxyRoutes[0] : new Route()
			if( isNew ) route.label = `Affiliate Shortcut URL - ${ this.newItem.name }`
			
			const source1 = route.createSource()
			source1.domain = "eknowledge.com"
			source1.path = sourcePath

			const source2 = route.createSource()
			source2.domain = "www.eknowledge.com"
			source2.path = sourcePath

			const dst = route.createDestination()
			dst.domain = powerprep_live_domain
			dst.path = 'affiliate/' + this.newItem.urlSlug

			await ( isNew ? ProxyAPI.createRoute( route.export() ) : ProxyAPI.editRoute( route.export() ) )
			this.loadProxyRoutes()
		},


		async addCustomRoute() {
			const isNew = this.proxyRoutes.length === 0
			const route = this.proxyRoutes.length ? this.proxyRoutes[0] : new Route()
			if( isNew ) route.label = `Affiliate Shortcut URL - ${ this.newItem.name }`

			const source1 = route.createSource()
			source1.domain = "eknowledge.com"
			source1.path = this.addRoutePath

			const source2 = route.createSource()
			source2.domain = "www.eknowledge.com"
			source2.path = this.addRoutePath

			const dst = route.createDestination()
			dst.domain = powerprep_live_domain
			dst.path = 'affiliate/' + this.newItem.urlSlug

			await ( isNew ? ProxyAPI.createRoute( route.export() ) : ProxyAPI.editRoute( route.export() ) )
			
			this.loadProxyRoutes()
			this.showAddRoute = false
			this.addRoutePath = ''
		},


		async isRouteAvailable( sourcePath = null ) {
			if( !this.addRoutePath ) return true
			const res = await ProxyAPI.searchRoutes( { sourceDomain: "eknowledge.com", sourcePath, fuzzyBefore: false, fuzzyAfter: false } )
			
			if( res.data && res.data.length > 0 ) return false
			return true
		},


		async toggleRouteEnabled( route ) {
			if( route.enabled ) ProxyAPI.enableRoute( route.id )
			else ProxyAPI.disableRoute( route.id )
		},


	}



}
</script>

<style scoped>
input, textarea { padding: 0.5em !important; }

.section {
	display: flex;
	border-left: 5px solid gray;

	padding: 0.5em;
	margin: 3em 4em;
	position: relative;
}
.section:first-child {
	margin-top: 0;
}
.section:hover {
	border-color: var(--ekno-blue);
	box-shadow: 0 4px 12px -4px #888;
}



</style>
