<template>
<section>
		
	<div class="flex-row flex-align-center flex-justify-between flex-gap-2">
		<div>{{ count ? count.toLocaleString() : count }} orders on {{ pages }} pages</div>
		<div class="flex-row flex-align-center flex-gap-2">
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<span class="icon-file-excel" style="font-size: 1.5em;" @click="getOrders( true )" />
		</div>
	</div>


	<div class='font-small' v-if="orders.length">
		<table class="table">
			<tr>
				<th class='table-header'>#</th>
				<th class='table-header'>ID</th>
				<th class='table-header'>User Info</th>
				<th class='table-header'>Affiliate / Source</th>
				<th class='table-header'>Price</th>
				<th class='table-header'>Amt Paid</th>
				<th class='table-header'>Status</th>
				<th class='table-header'>Timestamp</th>
				<th class='table-header'></th>
			</tr>

			<tr v-for="(order, index) in orders"
				:key="index"
				class="table-row clickable"
				@click.stop="showOrderDetails(order, index)"
			>
				<td class='table-data'>{{ index + 1 }}</td>
				<td class='table-data'>{{ order.orderID }}</td>

				<td class='table-data'>
					<div>
						<span v-if='order.firstName && order.lastName'
							:class='{ "link": RenderLinks }'
							@click.stop='showUserDetails(order.userID)'
						>
							{{ order.firstName }} {{ order.lastName }}
						</span>

						<span v-if="order.customerID && order.customerName">
							(Customer: <span @click.stop='showCustomerDetails(order.customerID)' :class='{ "link": RenderLinks }'>{{ order.customerName }}</span>)
						</span>
					</div>
					<div>{{ order.emailAddress }}</div>
				</td>

				<td>
					{{order.affiliateName}}
					<span v-if="order.affiliateName && order.paymentMethod"> &mdash; </span>
					<span
						v-if="order.paymentMethod"
						style="color: #888"
						:class="{'v9order' : order.paymentMethod.toUpperCase().includes('V9')}">
						{{order.paymentMethod.substring(0, 30)}}
						<span v-if="order.paymentMethod.length > 30">&hellip;</span>
					</span>
				</td>

				<td>${{ order.totalCost }}</td>
				<td :class="{ 'red' : order.totalCost && (!order.totalPaid || order.totalPaid < order.totalCost) }">
					${{ order.totalPaid }}
				</td>

				<td>
					<span v-if="order.isProposal" class="proposed">Proposal</span>
					<span v-else-if="!order.isPaid && !order.isFulfilled" class="orange">Invoiced</span>
					<span v-else-if="order.isPaid ? !order.isFulfilled : order.isFulfilled">   <!-- LOGICAL XOR -->
						<span :class="{'red':!order.isPaid}">{{order.isPaid ? 'Paid' : 'Unpaid'}}</span><br />
						<span :class="{'red':!order.isFulfilled}">{{order.isFulfilled ? 'Fulfilled' : 'Unfulfilled'}}</span>
					</span>
					<span v-else-if="order.isPaid && order.isFulfilled" class="green">Complete</span>
					<span v-if="order.dateCanceled" class="canceled"> (CANCELED)</span>
				</td>

				<td>
					{{ orderDate(order) }}
				</td>
				<td @click.stop="deleteOrder(order.orderID)">
					<span class="icon-bin" />
				</td>
			</tr>
		</table>


		<UserDetailsModal :user="selectedUser" :show="showSelectedUser" @close="showSelectedUser = false" />
		<CustomerDetailsModal :customer="selectedCustomer" :show="showSelectedCustomer" @close="showSelectedCustomer = false" />
		<OrderDetailsModal
			:order="selectedOrder"
			:orderIndex="selectedOrderIndex"
			:numOrders="orders.length"
			@orderSaved="orderSaved"
			@next="selectNextOrder()"
			@previous="selectPreviousOrder()"
			ref="selectedOrderModal" />

		<Paginator v-model="page" :numPages="pages" />
	</div>
	<p v-else style="text-align:center;">No orders</p>
</section>
</template>


<script>
import OrderDetailsModal from '@/features/orders/OrderDetailsModal.vue'
import Paginator from '@/components/utilities/Paginator.vue'

import Order from '@/features/orders/Order.js'
import Customer from '@/features/SalesManagement/Customers/Customer.js'

import PaginatedRequest from '@/api/PaginatedRequest.js'
import OrdersAPI from '@/api/OrdersAPI.js'
import UserAPI from '@/api/UserAPI.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'


export default {
	name: 'OrdersList',

	components: {
		UserDetailsModal: () => import('@/features/Users/UserDetailsModal.vue'),
		CustomerDetailsModal: () => import('@/features/SalesManagement/Customers/CustomerDetailsModal.vue'),
		OrderDetailsModal,
		Paginator,
	},

	props: {
		userID: {
			type: Number,
			default: null
		},
		customerID: {
			type: Number,
			default: null
		},
		hasCustomer: {
			type: Boolean,
			default: null
		},
		affiliateID: {
			type: Number,
			default: null
		},
		hasAffiliate: {
			type: Boolean,
			default: null
		},
		orderState: {
			type: String,
			default: null
		},
		contractState: {
			type: [String, Boolean],
			default: null
		},
		searchString: {
			type: String,
			default: null
		},
		start: {
			type: [String, Number],
			default: null
		},
		end: {
			type: [String, Number],
			default: null
		},

		RenderLinks: Boolean
	},

	data() {
		return {
			orders: [],
			selectedUser: null,
			selectedOrder: null,
			selectedOrderIndex: null,
			selectedCustomer: null,

			count: 0,
			page: 1,
			pages: 0,
			sortBy: null,
			sortAsc: false,
			pageSize: 100,

			loadedAffiliates: new Map(),

			showSelectedUser: false,
			showSelectedCustomer: false,
		}
	},


	created() {
		document.addEventListener("keydown", this.prevOrderKeyboardEvent, false)
		document.addEventListener("keydown", this.nextOrderKeyboardEvent, false)
		this.getOrders()
	},
	destroyed() {
		document.removeEventListener("keydown", this.prevOrderKeyboardEvent, false)
		document.removeEventListener("keydown", this.nextOrderKeyboardEvent, false)
	},


	watch: {
		customerID() { this.page = 1; this.getOrders() },
		hasCustomer() { this.page = 1; this.getOrders() },
		affiliateID() { this.page = 1; this.getOrders() },
		hasAffiliate() { this.page = 1; this.getOrders() },
		orderState() { this.page = 1; this.getOrders() },
		contractState() { this.page = 1; this.getOrders() },
		searchString() { this.page = 1; this.getOrders() },
		start() { this.page = 1; this.getOrders() },
		end() { this.page = 1; this.getOrders() },
		pageSize() { this.page = 1; this.getOrders() },
		page() { this.getOrders() },
	},

	methods: {
		orderDate(order) {
			return order.timestamp.toLocaleString();
		},

		showOrderDetails(order, index) {
			this.selectedOrder = order
			this.selectedOrderIndex = index
			this.$refs.selectedOrderModal.open()
		},

		async showUserDetails(userID) {
			if (!this.RenderLinks) return;

			this.selectedUser = await UserAPI.getUser(userID);
			this.showSelectedUser = true
		},

		async showCustomerDetails(customerID) {
			if (!this.RenderLinks) return;

			this.selectedCustomer = Customer.import(await SalesManagementAPI.getCustomer(customerID));
			this.showSelectedCustomer = true
		},

		async getOrders( asCSV = false ) {
			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
			if( this.searchString ) req.searchString = this.searchString

			req.userID = this.userID
			req.customerID = this.customerID
			req.hasCustomer = this.hasCustomer
			req.affiliateID = this.affiliateID
			req.hasAffiliate = this.hasAffiliate
			req.start = this.start
			req.end = this.end
			req.csv = asCSV

			req.orderState = this.orderState
			if(this.contractState == null) req.isContract = null
			if(this.contractState == false) req.isContract = false
			if(this.contractState == 'isContract') req.isContract = true
			if(this.contractState == 'isExecutedContract') req.isExecutedContract = true
			if(this.contractState == 'isActiveContract') req.isActiveContract = true
			if(this.contractState == 'isExpiredContract') req.isExpiredContract = true
			if(this.contractState == 'isPendingContract') req.isPendingContract = true

			const res = await OrdersAPI.getOrders( req, asCSV );
			if( asCSV ) return
			
			this.orders = [];

			for(let obj of res.data) {
				let order = Order.import(obj);
				this.orders.push(order);
			} // for res.orders

			this.pages = res.pages;
			this.count = res.count
		},

		orderSaved(newOrder) {
			for(let i=0; i<this.orders.length; i++) {
				if(this.orders[i].orderID !== newOrder.orderID) continue;
				this.orders[i] = newOrder
				this.selectedOrder = newOrder
				return true;
			}
			return false;
		},

		async deleteOrder(orderID) {
			let res = confirm(`Are you sure you want to delete order #${orderID}?`);
			if(!res) return;
			await OrdersAPI.deleteOrder(orderID);
			await this.getOrders();
		},


		selectNextOrder() {
			if(this.selectedOrderIndex + 1 >= this.orders.length) return
			this.selectedOrder = this.orders[++this.selectedOrderIndex]
		},


		selectPreviousOrder() {
			if(this.selectedOrderIndex <= 0) return
			this.selectedOrder = this.orders[--this.selectedOrderIndex]
		},


		prevOrderKeyboardEvent(e) {
			if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode == 37) {
				e.preventDefault();
				this.selectPreviousOrder();
			}
		},

		nextOrderKeyboardEvent(e) {
			if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode == 39) {
				e.preventDefault();
				this.selectNextOrder();
			}
		},

		confirmClose(event, modalRef) {
			if( !modalRef.changesMade ) return

			const yes = confirm("You have unsaved changes that will be lost.  \nAre you sure you want to close this window?")
			if(!yes) event.stop()
		}


	}
}
</script>

<style scoped>
.red {
	color: red;
	font-weight: bold;
}

.proposed { color: gray; }
.orange { color: orange; }
.green { color: green; }
.canceled { color: purple; }
.v9order { font-weight: bold; color: var(--ekno-blue) !important; }
</style>
